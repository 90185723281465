import httpUtil from "@/utils/httpUtil";
/**获得高级表单设置 */
export function getHighFormOptionList(params) {
    return httpUtil.post("/api/crmPc/highForm/getHighFormOptionList", params)
}
/**新增修改高级表单设置 */
export function setHighFormOption(params) {
    return httpUtil.post("/api/crmPc/highForm/setHighFormOption", params)
}
/**获得高级表单反馈列表 */
export function getHighFormRenderList(params) {
    return httpUtil.post("/api/crmPc/highForm/getHighFormRenderList", params)
}
/**新增修改高级表单设置 */
export function setHighFormRender(params) {
    return httpUtil.post("/api/crmPc/highForm/setHighFormRender", params)
}