<template>
    <div class="view pa24">
        <div class="d-flex">
            <div>
                <el-button type="primary" @click="showAddModel">添加表单</el-button>
            </div>
        </div>
        <commonTable :tableData="tableData" :loading="loading" @handleSizeChange="handleSizeChange"
            @handleCurrentChange="handleCurrentChange" :currentPage="currentPage" :total="total">
            <template v-slot:table>
                <el-table-column type="index" align="center" label="序号" />
                <el-table-column prop="title" align="center" label="表单标题" show-overflow-tooltip />
                <el-table-column prop="formType" align="center" label="使用场景" show-overflow-tooltip>
                    <template slot-scope="scope">
                        {{ formTypeData[scope.row.formType] }}
                    </template>
                </el-table-column>
                <el-table-column prop="formNumber" align="center" width="100" label="组件数" show-overflow-tooltip />
                <el-table-column align="center" label="操作" width="200">
                    <template slot-scope="scope">
                        <el-button class="" type="text" @click="showUpdateModel(scope.row)">编辑</el-button>
                        <el-button class="" type="text" @click="showFeedbackListModel(scope.row)">反馈列表</el-button>
                        <el-button class="" style="color:#F56C6C" type="text" @click="setPutOn(scope.row, false)"
                            v-if="scope.row.isPutOn">下架
                        </el-button>
                        <el-button class="" v-if="!scope.row.isPutOn" @click="setPutOn(scope.row, true)" type="text">上架
                        </el-button>
                    </template>
                </el-table-column>
            </template>
        </commonTable>
        <el-dialog :close-on-click-modal="false" :modal-append-to-body="false" title="设置高级表单" :visible.sync="showModel"
            :destroy-on-close="true" width="90%" center>
            <el-form :model="formData" :rules="rules" ref="formData" label-width="78px">
                <el-form-item label="表单标题" prop="title">
                    <el-input v-model="formData.title" style="width:300px;" placeholder="请输入表单标题"></el-input>
                </el-form-item>
                <el-form-item label="使用场景" prop="formType">
                    <el-select v-model="formData.formType" style="width:300px;" placeholder="请选择使用场景">
                        <el-option v-for="(name, id) in formTypeData" :key="id" :label="name" :value="id">
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <div class="d-flex">
                <span class="mr10">允许一个用户多次参与</span>
                <el-switch v-model="formData.canAgain">
                </el-switch>
            </div>
            <br>
            <common-edit-form ref="commonEditForm" :initFormData="formData.detail"></common-edit-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="showModel = false">取 消</el-button>
                <el-button type="primary" @click="submitData" :loading="loadingBtn">提 交</el-button>
            </span>
        </el-dialog>
        <el-dialog :close-on-click-modal="false" :modal-append-to-body="false" :title="showFormData.title + '反馈列表'"
            :visible.sync="showFeedbackList" :destroy-on-close="true" width="800px" center>
            <div class="flex-a-b-c">
                <p style="font-size:15px;">
                    {{ formTypeData[showFormData.formType] }}
                </p>
                <el-button-group>
                    <el-button type="primary" @click="showExportNumberModel('numberExport')">范围导出</el-button>
                    <el-button type="primary" @click="exportList" :loading="exportBtnLoading">导出当前页</el-button>
                    <el-button type="primary" @click="showExportNumberModel('statusExport', 1)">导出已通过</el-button>
                    <el-button type="primary" @click="showExportNumberModel('statusExport', 2)">导出不通过</el-button>
                </el-button-group>
            </div>
            <commonTable :tableData="feedbackTableData" :loading="feedbackLoading"
                @handleSizeChange="feedbackHandleSizeChange" @handleCurrentChange="feedbackHandleCurrentChange"
                :currentPage="feedbackCurrentPage" :total="feedbackTotal">
                <template v-slot:table>
                    <el-table-column type="index" align="center" label="序号" />
                    <el-table-column prop="name" align="center" label="反馈用户" show-overflow-tooltip />
                    <el-table-column prop="createTime" align="center" label="提交时间" show-overflow-tooltip />
                    <el-table-column align="center" label="审核" width="80">
                        <template slot-scope="scope">
                            <div class="el-icon-warning w" v-if="scope.row.auditStatus === 0" title="待审核"></div>
                            <div v-else-if="scope.row.auditStatus === 1" class="el-icon-success c" title="已通过"></div>
                            <div v-else class="el-icon-error e" title="不通过"></div>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" label="操作" width="80">
                        <template slot-scope="scope">
                            <div class="showDetails el-icon-s-order" @click="showApplyModel(scope.row)"></div>

                        </template>
                    </el-table-column>
                </template>
            </commonTable>
            <span slot="footer" class="dialog-footer">
                <el-button @click="showFeedbackList = false">取 消</el-button>
            </span>
        </el-dialog>
        <el-dialog :close-on-click-modal="false" :modal-append-to-body="false" title="" :visible.sync="showExportNumber"
            :destroy-on-close="true" width="300px" center>
            <p class="mb10">用户反馈导出条数</p>
            <el-input v-model="exportNumber" placeholder="请输入导出条数" type="number"></el-input>
            <span slot="footer" class="dialog-footer">
                <el-button @click="numberExport" type="primary" :loading="exportBtnLoading">导出用户反馈</el-button>
            </span>
        </el-dialog>
        <el-dialog :close-on-click-modal="false" :modal-append-to-body="false" title="用户反馈" :visible.sync="showApply"
            :destroy-on-close="true" width="450px" center>
            <p>{{ renderDetails.title }}</p>
            <br>
            <show-common-edit-form :initFormData="renderDetails"></show-common-edit-form>
            <span slot="footer" class="dialog-footer">
                <el-button class="mr10" @click="showApply = false">取 消</el-button>
                <span v-if="auditStatus === 0">
                    <el-button @click="setAuditStatus()" type="primary" :loading="exportBtnLoading">通过</el-button>
                    <el-button @click="setAuditStatus()" type="danger" :loading="exportBtnLoading">不通过</el-button>
                </span>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import commonTable from "@/components/common/commonTable";
import { getHighFormOptionList, setHighFormOption, getHighFormRenderList, setHighFormRender } from "@/api/highForm";
import commonEditForm from "@/components/common/commonEditForm";
import showCommonEditForm from "@/components/common/showCommonEditForm";
let base64 = require('js-base64').Base64
export default {
    name: "highForm",
    components: {
        commonTable,
        commonEditForm,
        showCommonEditForm
    },
    data() {
        return {
            //列表配置
            currentPage: 1, //当前页
            pageSize: 10, //显示条数
            loading: false, //表格加载
            total: 0, //总条数
            showModel: false,
            tableData: [],
            formTypeData: {
                0: "入会申请",
                1: "问卷调查",
                2: "报名登记",
                3: "在线预约",
                4: "信息采集",
                5: "在线考评"
            },
            auditStatusData: {
                0: "待审核",
                1: "通过",
                2: "不通过"
            },
            //添加修改配置
            formData: {
                canAgain: false,
                formType: "",
                title: "",
                detail: {
                    title: "",
                    content: "",
                    canAgain: false,
                    selectIndex: "",
                    detailRequests: []
                },
            },
            rules: {
                title: {
                    required: true,
                    trigger: "blur",
                    message: "请输入表单名称",
                },
                formType: {
                    required: true,
                    trigger: "change",
                    message: "请选择使用场景",
                },
            },
            formType: "",
            updateId: 0,
            associationId: 0,
            loadingBtn: false,
            //反馈列表配置
            showFormData: {},
            formFeedbackId: 0,
            feedbackPageSize: 0,
            feedbackCurrentPage: 0,
            feedbackLoading: false, //表格加载
            feedbackTotal: 0, //总条数
            feedbackTableData: [],
            showFeedbackList: false,
            formTypeObj: {
                "SINGLE": "单选项",
                "MULTIPLE": "多选项",
                "DROP_DOWN": "下拉选项",
                "TEXTAREA": "文本",
                "TEXTAREAINPUT": "输入框",
                // { type: "SCOURE", title: "评分", icon: "iconFormscore" },
                "IMAGE": "图片",
                "GPS": "地址定位",
                // { type: "USERINFO", title: "个人信息", icon: "iconFormgerenxinxi_o" },
                "DATEPICKER": "日期选择",
                "TIMEPICKER": "时间选择",
                "WEBSITE": "网址",
                "ATTACHMENT": "附件",
            },
            //导出配置
            exportNumber: 10,
            showExportNumber: false,
            exportAuditStatus: 0,
            exportType: "",
            exportBtnLoading: false,
            //反馈详情 
            showApply: false,
            renderDetails: [],
            renderId: 0,
            auditStatus: 0,
        };
    },
    computed: {
        watchAssociation() {
            return this.$store.state.checkedAssociation
        }
    },
    watch: {
        watchAssociation(row) {
            this.associationId = row.associationId;
            this.getList();
            this.showFeedbackList = false;
        },
    },
    created() {
        this.associationId = JSON.parse(sessionStorage.getItem("checkedAssociation")).associationId;
        this.getList();
    },
    methods: {
        showApplyModel(data) {
            this.renderDetails = data.detailJson;
            this.showApply = true;
            this.renderId = data.renderId;
            this.auditStatus = data.auditStatus
        },
        /**@method 审核反馈内容 */
        setAuditStatus(auditStatus) {
            setHighFormRender({
                renderId: this.renderId,
                auditStatus: auditStatus
            }).then(res => {
                if (res.code === 200) {
                    this.getHighFormRenderList();
                } else {
                    this.$message.error(res.message)
                }
            }).catch(err => {
                if (err.code === 201) {
                    this.$message.error(err.message)
                }
            })
        },
        showExportNumberModel(type, auditStatus) {
            this.showExportNumber = true;
            this.exportType = type;
            this.exportAuditStatus = auditStatus;
        },
        /**@method 条数导出 */
        numberExport() {
            this.exportList(this.exportType);
        },
        /**@method 反馈导出 */
        exportList(type) {
            let params = {
                formId: this.formFeedbackId,
            }
            if (type === 'statusExport') {
                params.auditStatus = this.exportAuditStatus;
                params.pageSize = this.exportNumber;
                params.pageNum = 1;
            } else if (type === 'numberExport') {
                params.auditStatus = this.exportAuditStatus;
                params.pageSize = this.exportNumber;
                params.pageNum = 1;
            } else {
                params.pageSize = this.feedbackPageSize;
                params.pageNum = this.feedbackCurrentPage;
            }
            this.exportBtnLoading = true;
            fetch('/api/crmPc/highForm/exportHighFormRender?data=' + base64.encode(JSON.stringify(params)), {
                headers: {
                    token: localStorage.getItem('token'),
                    'content-type': 'application/json'
                },
                method: 'POST',
            }).then(res => {
                return res.blob();
            }).then(res => {
                let link = document.createElement('a');
                let url = window.URL.createObjectURL(res);
                link.href = url;
                link.download = '用户反馈.xls';
                link.click();
                window.URL.revokeObjectURL(url);
                this.exportBtnLoading = false;
                this.showExportNumber = false;
            }).catch(err => {
                this.exportBtnLoading = false;
            });
        },
        /**@method 显示反馈列表 */
        showFeedbackListModel(row) {
            this.formFeedbackId = row.formId;
            this.showFormData = row;
            this.showFeedbackList = true;
            this.feedbackCurrentPage = 1;
            this.getHighFormRenderList();
        },
        /**@method 获取反馈列表 */
        getHighFormRenderList() {
            let params = {
                formId: this.formFeedbackId,
                pageSize: this.feedbackPageSize,
                pageNum: this.feedbackCurrentPage,
            };
            this.feedbackLoading = true;
            getHighFormRenderList(params).then(res => {
                this.feedbackLoading = false;
                if (res.code === 200) {
                    const { data } = res;
                    let feedbackTableData = [];
                    for (let row of data.pageInfo.list) {
                        feedbackTableData.push(row)
                    }
                    this.feedbackTableData = feedbackTableData;//列表内容
                    this.feedbackTotal = data.pageInfo.total;
                } else {
                    this.feedbackTableData = [];
                    this.feedbackTotal = 0;
                }
            }).catch(err => {
                this.feedbackLoading = false;
                this.feedbackTableData = [];
                this.feedbackTotal = 0;
            })
        },
        /**@method 切换行 */
        feedbackHandleSizeChange(val) {
            this.feedbackPageSize = val;
            this.getHighFormRenderList();
        },
        /**@method 切换下一页 */
        feedbackHandleCurrentChange(val) {
            this.feedbackCurrentPage = val;
            this.getHighFormRenderList();
        },
        /**@method 修改上下架 */
        setPutOn(row, puton) {
            setHighFormOption({
                formId: row.formId,
                isPutOn: puton
            }).then(res => {
                if (res.code === 200) {
                    this.getList();
                } else {
                    this.$message.error(res.message)
                }
            }).catch(err => {
                if (err.code === 201) {
                    this.$message.error(err.message)
                }
            })
        },
        submitData() {
            //修改添加会员等级
            this.$refs.formData.validate((valid) => {
                if (valid) {
                    if (this.$refs.commonEditForm.detailRequests.length <= 0) {
                        this.$message.warning("请选择组件")
                        return;
                    }
                    let params = {
                        associationId: this.associationId,
                        accountType: 9,
                        ...this.formData
                    };
                    this.loadingBtn = true;
                    let message = "保存成功";
                    if (this.formType === 'update') {
                        message = "编辑成功";
                        params.formId = this.updateId;
                    }
                    params.detail = JSON.stringify({
                        title: this.formData.title,
                        content: "",
                        selectIndex: "",
                        canAgain: this.formData.canAgain,
                        buttonName: this.$refs.commonEditForm.btnName,
                        detailRequests: this.$refs.commonEditForm.detailRequests
                    });
                    setHighFormOption(params).then(res => {
                        this.loadingBtn = false;
                        if (res.code === 200) {
                            this.$message.success(message)
                            this.currentPage = 1
                            this.getList();
                            this.showModel = false;
                        } else {
                            this.$message.error(res.message)
                        }
                    }).catch(err => {
                        this.loadingBtn = false;
                        if (err.code == 201) {
                            this.$message.error(err.message)
                        }
                    })
                }
            });
        },
        /**@method 获取列表 */
        getList() {
            let params = {
                pageSize: this.pageSize,
                pageNum: this.currentPage,
                associationId: this.associationId
            };
            this.loading = true;
            getHighFormOptionList(params).then(res => {
                this.loading = false;
                if (res.code === 200) {
                    let tableData = [];
                    const { data } = res;
                    for (let row of data.pageInfo.list) {
                        row.formNumber = row.detailJson.detailRequests.length;
                        tableData.push(row);
                    }
                    this.tableData = tableData;
                    this.total = data.pageInfo.total;
                }
            }).catch(err => {
                this.loading = false;
                this.tableData = [];
                this.total = 0;
            })

        },
        /**@method 切换行 */
        handleSizeChange(val) {
            this.pageSize = val;
            this.getList();
        },
        /**@method 切换下一页 */
        handleCurrentChange(val) {
            this.currentPage = val;
            this.getList();
        },
        /**@method 显示新增模态框 */
        showAddModel() {
            this.showModel = true;
            this.formType = "add";
            this.formData = {
                canAgain: false,
                formType: "",
                title: "",
                detail: {
                    title: "",
                    content: "",
                    canAgain: false,
                    selectIndex: "",
                    detailRequests: []
                },
            };
            this.updateId = 0;
        },
        /**@method 编辑
         * @param {Object} row - 当前点击行的值
         */
        showUpdateModel(row) {
            this.showModel = true;
            this.formType = "update";
            this.updateId = row.formId;
            this.formData = {
                canAgain: row.canAgain,
                formType: String(row.formType),
                title: row.title,
                detail: JSON.parse(row.detail),//title content canAgain selectIndex detailRequests
            };
        }
    },
};
</script>

<style lang="scss" scoped>
.switchInput {
    display: flex;

    .selectType {
        width: 150px;
    }
}


.showDetails {
    font-size: 20px;
    cursor: pointer;
    color: #51cbcd;
    transition: all 0.1s;
}

.showDetails:hover {
    transform: scale(1.1);
}

.operation-left {
    margin-bottom: 20px;

    .el-button {
        width: 95px;
        height: 32px;
        background: #51cbcd;
        font-size: 14px;
        font-weight: 400;
        color: #ffffff;

        &:active {
            border-color: #51cbcd;
        }
    }
}
</style>