<template>
    <div class="formContent pa10">
        <div class="formItem" v-for="(row, index) in initFormData.detailRequests" :key="index">
            <div v-if="row.formType == 'SINGLE'">
                <div class="flex-a-b-c mb10">
                    <p><span v-if="row.required" class="req">*</span>{{ row.title }}</p>
                </div>
                <div class="formValue">
                    <div class="curtain"></div>
                    <el-radio-group v-model="row.select">
                        <div class="childrenDetails mb5" v-for="(child, key) in row.childrenDetails" :key="key">
                            <el-radio :label="key">{{
                                child.title }}</el-radio>
                        </div>
                    </el-radio-group>
                </div>
            </div>
            <div v-if="row.formType == 'MULTIPLE'">
                <div class="flex-a-b-c mb10">
                    <p><span v-if="row.required" class="req">*</span>{{ row.title }}</p>
                </div>
                <div class="formValue">
                    <div class="curtain"></div>
                    <el-checkbox-group v-model="row.select">
                        <div class="childrenDetails" v-for="(child, key) in row.childrenDetails" :key="key">
                            <el-checkbox :label="key">{{
                                child.title }}</el-checkbox>
                        </div>
                    </el-checkbox-group>
                </div>
            </div>
            <div v-if="row.formType == 'DROP_DOWN'">
                <div class="flex-a-b-c mb10">
                    <p><span v-if="row.required" class="req">*</span>{{ row.title }}</p>
                </div>
                <div class="formValue">
                    <div class="curtain"></div>
                    <el-select class="pl10" v-model="row.select" style="width:100%;" @change="row.select = ''"
                        placeholder="下拉选项">
                        <el-option v-for="(child, key) in row.childrenDetails" :key="key" :label="child.title" :value="key">
                        </el-option>
                    </el-select>
                </div>
            </div>
            <div v-if="row.formType == 'TEXTAREA'">
                <div class="flex-a-b-c mb10">
                    <p></p>

                </div>
                <div>{{ row.title }}</div>
            </div>
            <div v-if="row.formType == 'SCOURE'">
                <div class="flex-a-b-c mb10">
                    <p><span v-if="row.required" class="req">*</span>{{ row.title }}</p>

                </div>
                <div class="d-flex ml10" :class="{ 'mb10': key < (row.childrenDetails.length - 1) }"
                    v-for="(child, key) in row.childrenDetails" :key="key">
                    <div class="mr10">
                        {{ child.title }}
                    </div>
                    <el-rate v-model="row.content" :disabled="true" :colors="colors"> </el-rate>
                </div>
            </div>
            <div v-if="row.formType == 'IMAGE'">
                <div class="imageContent">
                    <el-carousel height="180px" v-if="row.childrenDetails.length > 0" :interval="3000">
                        <el-carousel-item v-for="(child, index) in row.childrenDetails" :key="index">
                            <el-image :src="child.imgNum" style="width:100%;height:180px"></el-image>
                        </el-carousel-item>
                    </el-carousel>
                    <div class="nullImg flex-c-c" v-else style="width:100%;height:180px">
                        <i class="el-icon-picture"></i>
                    </div>
                </div>
            </div>
            <div v-if="row.formType == 'GPS'">
                <div class="flex-a-b-c mb10">
                    <p><span v-if="row.required" class="req">*</span>{{ row.title }}</p>

                </div>
                <div class="ml10">
                    <div class="formValue">
                        <div class="curtain"></div>
                        <div>

                            <div v-if="row.content">
                                <el-input placeholder="省/市/区" v-model="row.content"></el-input>
                            </div>
                            <div v-else>
                                <el-select class="pr10" disabled v-model="row.select" style="width:105px;"
                                    @change="row.select = ''" placeholder="下拉选项">
                                    <el-option label="省" :value="''">
                                    </el-option>
                                </el-select>
                                <el-select class="pr10" disabled v-model="row.select" style="width:105px;"
                                    @change="row.select = ''" placeholder="下拉选项">
                                    <el-option label="市" :value="''">
                                    </el-option>
                                </el-select>
                                <el-select v-model="row.select" disabled style="width:105px;" @change="row.select = ''"
                                    placeholder="下拉选项">
                                    <el-option label="区" :value="''">
                                    </el-option>
                                </el-select>
                            </div>
                        </div>
                        <div class="mt10">
                            <el-input placeholder="详情地址" v-model="row.address"></el-input>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="row.formType == 'USERINFO'">
                <div class="flex-a-b-c mb10">
                    <p><span v-if="row.required" class="req">*</span>{{ row.title }}</p>

                </div>
                <div class="ml10" :class="{ 'mb10': key < (row.childrenDetails.length - 1) }"
                    v-for="(child, key) in row.childrenDetails" :key="key">
                    <div class="mb10">{{ child.title }}</div>
                    <el-input placeholder="请输入个人信息" disabled :label="child.content" :value="child.content"></el-input>
                </div>
            </div>
            <div v-if="row.formType == 'TEXTAREAINPUT'">
                <div class="flex-a-b-c mb10">
                    <p><span v-if="row.required" class="req">*</span>{{ row.title }}</p>
                </div>
                <div class="formValue">
                    <div class="curtain"></div>
                    <el-input class="pl10" :placeholder="row.placeholder" v-model="row.content"></el-input>
                </div>
            </div>
            <div v-if="row.formType == 'DATEPICKER'">
                <div class="flex-a-b-c mb10">
                    <p><span v-if="row.required" class="req">*</span>{{ row.title }}</p>
                </div>
                <div class="ml10">
                    <div class="formValue">
                        <div class="curtain"></div>
                        <el-date-picker v-model="row.content" value-format="yyyy-MM-dd" type="dates" style="width: 100%"
                            range-separator="至" start-placeholder="日期">
                        </el-date-picker>
                    </div>
                </div>
            </div>
            <div v-if="row.formType == 'TIMEPICKER'">
                <div class="flex-a-b-c mb10">
                    <p><span v-if="row.required" class="req">*</span>{{ row.title }}</p>

                </div>
                <div class="ml10">
                    <div class="formValue">
                        <div class="curtain"></div>
                        <el-time-picker v-model="row.content" value-format="HH:mm" style="width:100%"
                            placeholder="选择时间">
                        </el-time-picker>
                    </div>
                </div>
            </div>
            <div v-if="row.formType == 'WEBSITE'">
                <div class="flex-a-b-c mb10">
                    <p><span v-if="row.required" class="req">*</span>{{ row.title }}</p>

                </div>
                <div class="formValue">
                    <div class="curtain"></div>
                    <div v-if="row.content">
                        <el-input class="pl10" v-model="row.content"></el-input>
                    </div>
                    <div class="flex-a-c ml10 yqInput" v-else>
                        <div class="iconForm iconFormyuanshuju-wangzhi"></div>
                        <div>例如：https://www.yuanqi366.com</div>
                    </div>
                </div>
            </div>
            <div v-if="row.formType == 'ATTACHMENT'">
                <div class="flex-a-b-c mb10">
                    <p><span v-if="row.required" class="req">*</span>{{ row.title }} 点击查看附件</p>

                </div>
                <div class="ml10">
                    <a :href="file" target="_blank" class="fileDownloadFun mb5" v-for="(file, i) in row.content" :key="i">{{
                        row.title +
                        (i
                            + 1) }}</a>
                </div>
            </div>
        </div>
        <div class="formItem">
            <el-button type="primary" style="width:100%">{{ initFormData.buttonName ? initFormData.buttonName : '提 交'
            }}</el-button>
        </div>
    </div>
</template>

<script>
export default {
    props: ['initFormData'],
    data() {
        return {

        }
    }
}
</script>

<style lang="scss" scoped>
.fileDownloadFun {
    color: #51cbcd;
    display: block;
}

.yqInput {
    height: 32px;
    border: 1px solid #E4E7ED;
    border-radius: 4px;
    // background-color: #F5F7FA;
    color: #C0C4CC;

    .iconForm {
        padding: 0 10px;
    }
}

.formContent {
    height: 600px;
    overflow-y: scroll;
    border-radius: 5px;
    width: 400px;
    background: #fff;
    border: 1px solid #dcdfe6;

    .formItem {
        margin-top: 20px;
        position: relative;

        .curtain {
            z-index: 2;
            position: absolute;
            height: 100%;
            width: 100%;
        }

        .formValue {
            position: relative;
        }

        .childrenDetails {
            margin-left: 10px;
        }

        p {
            font-size: 15px;

            .req {
                color: red;
                margin-right: 5px;
            }

        }

        .deleteForm {
            color: #F56C6C;
            z-index: 2;
            cursor: pointer;
            font-size: 18px;
        }

        .imageContent {
            position: relative;

            .imageDeleteForm {
                position: absolute;
                right: 10px;
                top: 10px;
                border-radius: 50%;
                z-index: 3;
            }
        }


        .commentForm {
            border: 1px solid #dcdfe6;
            padding-top: 10px;
            margin-top: 10px;
        }
    }


}
</style>