<template>
    <div>
        <div class="d-flex justify-content-between">
            <div class="formType">
                <p class="fs8 fwbold">组件</p>
                <div class="formTypeList">
                    <div class="formTypeItem" v-for="(row, index) in formTypeData" :key="index"
                        @click="selectEnter(row.type, row.title)">
                        <div :class="'iconForm ' + row.icon"></div>
                        <div>{{ row.title }}</div>
                    </div>
                </div>
            </div>
            <div class="formContent pa10">
                <vuedraggable v-model="detailRequests" v-bind="{ animation: 200 }">
                    <transition-group>
                        <div class="formItem" :class="{ 'on': index == setIndex }" @click="selectForm(index)"
                            v-for="(row, index) in detailRequests" :key="index">
                            <div v-if="row.formType == 'SINGLE'">
                                <div class="flex-a-b-c mb10">
                                    <p><span v-if="row.required" class="req">*</span>{{ row.title }}</p>
                                    <i @click="deleteForm(index)" class="deleteForm el-icon-circle-close"></i>
                                </div>
                                <div class="childrenDetails" v-for="(child, key) in row.childrenDetails" :key="key">
                                    <el-radio :label="child.title">{{
                                        child.title }}</el-radio>
                                </div>
                            </div>
                            <div v-if="row.formType == 'MULTIPLE'">
                                <div class="flex-a-b-c mb10">
                                    <p><span v-if="row.required" class="req">*</span>{{ row.title }}</p>
                                    <i @click="deleteForm(index)" class="deleteForm el-icon-circle-close"></i>
                                </div>
                                <div class="childrenDetails" v-for="(child, key) in row.childrenDetails" :key="key">
                                    <el-checkbox :label="child.title">{{
                                        child.title }}</el-checkbox>
                                </div>
                            </div>
                            <div v-if="row.formType == 'DROP_DOWN'">
                                <div class="flex-a-b-c mb10">
                                    <p><span v-if="row.required" class="req">*</span>{{ row.title }}</p>
                                    <i @click="deleteForm(index)" class="deleteForm el-icon-circle-close"></i>
                                </div>
                                <el-select class="pl10" v-model="formData.select" style="width:100%;"
                                    @change="formData.select = ''" placeholder="下拉选项">
                                    <el-option v-for="(child, key) in row.childrenDetails" :key="key" :label="child.title"
                                        :value="child.title">
                                    </el-option>
                                </el-select>
                            </div>
                            <div v-if="row.formType == 'TEXTAREA'">
                                <div class="flex-a-b-c mb10">
                                    <p></p>
                                    <i @click="deleteForm(index)" class="deleteForm el-icon-circle-close"></i>
                                </div>
                                <div>{{ row.title }}</div>
                            </div>
                            <div v-if="row.formType == 'SCOURE'">
                                <div class="flex-a-b-c mb10">
                                    <p><span v-if="row.required" class="req">*</span>{{ row.title }}</p>
                                    <i @click="deleteForm(index)" class="deleteForm el-icon-circle-close"></i>
                                </div>
                                <div class="d-flex ml10" :class="{ 'mb10': key < (row.childrenDetails.length - 1) }"
                                    v-for="(child, key) in row.childrenDetails" :key="key">
                                    <div class="mr10">
                                        {{ child.title }}
                                    </div>
                                    <el-rate v-model="formData.rate" :disabled="true" :colors="colors"> </el-rate>
                                </div>
                            </div>
                            <div v-if="row.formType == 'IMAGE'">
                                <div class="imageContent">
                                    <i @click="deleteForm(index)"
                                        class="imageDeleteForm deleteForm el-icon-circle-close"></i>
                                    <el-carousel height="180px" v-if="row.childrenDetails.length > 0">
                                        <el-carousel-item v-for="(child, index) in row.childrenDetails" :key="index">
                                            <el-image :src="child.imgNum" style="width:100%;height:180px"></el-image>
                                        </el-carousel-item>
                                    </el-carousel>
                                    <div class="nullImg flex-c-c" v-else style="width:100%;height:180px">
                                        <i class="el-icon-picture"></i>
                                    </div>
                                </div>
                            </div>
                            <div v-if="row.formType == 'GPS'">
                                <div class="flex-a-b-c mb10">
                                    <p><span v-if="row.required" class="req">*</span>{{ row.title }}</p>
                                    <i @click="deleteForm(index)" class="deleteForm el-icon-circle-close"></i>
                                </div>
                                <div class="ml10">
                                    <div>
                                        <el-select class="pr10" disabled v-model="formData.select" style="width:105px;"
                                            @change="formData.select = ''" placeholder="下拉选项">
                                            <el-option label="省" :value="''">
                                            </el-option>
                                        </el-select>
                                        <el-select class="pr10" disabled v-model="formData.select" style="width:105px;"
                                            @change="formData.select = ''" placeholder="下拉选项">
                                            <el-option label="市" :value="''">
                                            </el-option>
                                        </el-select>
                                        <el-select v-model="formData.select" disabled style="width:105px;"
                                            @change="formData.select = ''" placeholder="下拉选项">
                                            <el-option label="区" :value="''">
                                            </el-option>
                                        </el-select>
                                    </div>
                                    <div class="mt10">
                                        <el-input disabled placeholder="详情地址"></el-input>
                                    </div>
                                </div>
                            </div>
                            <div v-if="row.formType == 'USERINFO'">
                                <div class="flex-a-b-c mb10">
                                    <p><span v-if="row.required" class="req">*</span>{{ row.title }}</p>
                                    <i @click="deleteForm(index)" class="deleteForm el-icon-circle-close"></i>
                                </div>
                                <div class="ml10" :class="{ 'mb10': key < (row.childrenDetails.length - 1) }"
                                    v-for="(child, key) in row.childrenDetails" :key="key">
                                    <div class="mb10">{{ child.title }}</div>
                                    <el-input placeholder="请输入个人信息" disabled :label="child.content"
                                        :value="child.content"></el-input>
                                </div>
                            </div>
                            <div v-if="row.formType == 'TEXTAREAINPUT'">
                                <div class="flex-a-b-c mb10">
                                    <p><span v-if="row.required" class="req">*</span>{{ row.title }}</p>
                                    <i @click="deleteForm(index)" class="deleteForm el-icon-circle-close"></i>
                                </div>
                                <el-input class="pl10" :placeholder="row.placeholder" disabled :label="row.content"
                                    :value="row.content"></el-input>
                            </div>
                            <div v-if="row.formType == 'DATEPICKER'">
                                <div class="flex-a-b-c mb10">
                                    <p><span v-if="row.required" class="req">*</span>{{ row.title }}</p>
                                    <i @click="deleteForm(index)" class="deleteForm el-icon-circle-close"></i>
                                </div>
                                <div class="ml10">
                                    <el-date-picker v-model="row.content" type="daterange" style="width: 100%"
                                        range-separator="至" :disabled="true" start-placeholder="开始日期"
                                        end-placeholder="结束日期">
                                    </el-date-picker>
                                </div>
                            </div>
                            <div v-if="row.formType == 'TIMEPICKER'">
                                <div class="flex-a-b-c mb10">
                                    <p><span v-if="row.required" class="req">*</span>{{ row.title }}</p>
                                    <i @click="deleteForm(index)" class="deleteForm el-icon-circle-close"></i>
                                </div>
                                <div class="ml10 flex-a-b-c">
                                    <el-time-picker v-model="formData.time" disabled style="width:100%" placeholder="选择时间">
                                    </el-time-picker>
                                </div>
                            </div>
                            <div v-if="row.formType == 'WEBSITE'">
                                <div class="flex-a-b-c mb10">
                                    <p><span v-if="row.required" class="req">*</span>{{ row.title }}</p>
                                    <i @click="deleteForm(index)" class="deleteForm el-icon-circle-close"></i>
                                </div>
                                <div class="flex-a-c ml10 yqInput">
                                    <div class="iconForm iconFormyuanshuju-wangzhi"></div>
                                    <div>例如：https://www.yuanqi366.com</div>
                                </div>
                            </div>
                            <div v-if="row.formType == 'ATTACHMENT'">
                                <div class="flex-a-b-c mb10">
                                    <p><span v-if="row.required" class="req">*</span>{{ row.title }}</p>
                                    <i @click="deleteForm(index)" class="deleteForm el-icon-circle-close"></i>
                                </div>
                                <div class="ml10">
                                    <div class="flex-a-c yqInput">
                                        <div class="iconForm iconFormattachment"></div>
                                        <div>选择文件（不超过20M）</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </transition-group>
                </vuedraggable>
                <div class="formItem" :class="{ 'on': 'btn' == setIndex }" @click="setIndex = 'btn'">
                    <el-button type="primary" style="width:100%">{{ btnName }}</el-button>
                </div>
            </div>
            <div class="formValue">
                <div v-if="detailRequests.length > 0 && setIndex != 'btn'">
                    <el-form v-if="detailRequests[setIndex].formType == 'SINGLE'" class="commentForm pa10">
                        <p class="fs8 fwbold mb10">单选项</p>
                        <el-form-item label="标题">
                            <el-input type="text" placeholder="请输入标题" v-model="detailRequests[setIndex].title"
                                style="width: 300px"></el-input>
                        </el-form-item>
                        <el-form-item label="必填">
                            <el-switch v-model="detailRequests[setIndex].required" active-text="开" inactive-text="关">
                            </el-switch>
                        </el-form-item>
                        <div class="ml10" v-for="(children, chIndex) in detailRequests[setIndex].childrenDetails"
                            :key="chIndex">
                            <el-form-item label="选项">
                                <div class="flex-c-c">
                                    <el-input type="text" class="mr10" placeholder="请输入选项" v-model="children.title">
                                    </el-input>
                                    <i class="hover_pointer el-icon-remove-outline hover_pointer"
                                        @click="deleteOption(detailRequests[setIndex].childrenDetails, chIndex)"></i>
                                </div>
                            </el-form-item>
                        </div>
                        <div class="ml10">
                            <el-button type="primary" icon="el-icon-circle-plus-outline"
                                @click="addOption(detailRequests[setIndex].childrenDetails, detailRequests[setIndex].formType)">添加单选
                            </el-button>
                        </div>
                    </el-form>

                    <el-form v-else-if="detailRequests[setIndex].formType == 'MULTIPLE'" class="commentForm pa10">
                        <p class="fs8 fwbold mb10">多选项</p>
                        <el-form-item label="标题">
                            <el-input type="text" placeholder="请输入标题" v-model="detailRequests[setIndex].title"
                                style="width: 300px" maxlength="10">
                            </el-input>
                        </el-form-item>
                        <el-form-item label="必填">
                            <el-switch v-model="detailRequests[setIndex].required" active-text="开" inactive-text="关">
                            </el-switch>
                        </el-form-item>

                        <div class="ml10" v-for="(children, chIndex) in detailRequests[setIndex].childrenDetails"
                            :key="chIndex">
                            <el-form-item label="选项">
                                <div class="flex-c-c">
                                    <el-input type="text" class="mr10" placeholder="请输入选项" v-model="children.title"
                                        maxlength="20">
                                    </el-input>
                                    <i class="hover_pointer el-icon-remove-outline"
                                        @click="deleteOption(detailRequests[setIndex].childrenDetails, chIndex)"></i>
                                </div>
                            </el-form-item>
                        </div>
                        <div class="ml10">
                            <el-button type="primary" icon="el-icon-circle-plus-outline"
                                @click="addOption(detailRequests[setIndex].childrenDetails, detailRequests[setIndex].formType)">添加多选</el-button>
                        </div>
                    </el-form>

                    <el-form v-else-if="detailRequests[setIndex].formType == 'DROP_DOWN'" class="commentForm pa10">
                        <p class="fs8 fwbold mb10 ml20">下拉选项</p>
                        <div>
                            <el-form-item label="标题">
                                <el-input type="text" placeholder="请输入标题" v-model="detailRequests[setIndex].title"
                                    style="width: 300px" maxlength="20">
                                </el-input>
                            </el-form-item>
                            <el-form-item label="必填">
                                <el-switch v-model="detailRequests[setIndex].required" active-text="开" inactive-text="关">
                                </el-switch>
                            </el-form-item>
                        </div>

                        <div class="ml10" v-for="(children, chIndex) in detailRequests[setIndex].childrenDetails"
                            :key="chIndex">
                            <el-form-item label="选项">
                                <div class="flex-c-c">
                                    <i class="hover_pointer el-icon-caret-bottom"></i>
                                    <el-input type="text" style="width: 200px" class="mr10" placeholder="请输入选项"
                                        v-model="children.title" maxlength="20">
                                    </el-input>
                                    <i class="hover_pointer el-icon-remove-outline"
                                        @click="deleteOption(detailRequests[setIndex].childrenDetails, chIndex)"></i>
                                </div>
                            </el-form-item>
                        </div>

                        <div class="ml10">
                            <el-button type="primary" icon="el-icon-circle-plus-outline"
                                @click="addOption(detailRequests[setIndex].childrenDetails, detailRequests[setIndex].formType)">添加单个下拉</el-button>
                        </div>
                    </el-form>

                    <el-form v-else-if="detailRequests[setIndex].formType == 'TEXTAREA'" class="commentForm pa10">
                        <p class="fs8 fwbold mb10 ml20">文本</p>
                        <div>
                            <el-form-item label="内容">
                                <el-input type="textarea" placeholder="请输入标题" v-model="detailRequests[setIndex].title"
                                    style="width: 300px" rows="5" maxlength="200">
                                </el-input>
                            </el-form-item>
                        </div>
                    </el-form>

                    <el-form v-else-if="detailRequests[setIndex].formType == 'SCOURE'" class="commentForm pa10">
                        <p class="fs8 fwbold mb10 ml20">评分</p>
                        <div>
                            <el-form-item label="标题">
                                <el-input type="text" placeholder="请输入标题" v-model="detailRequests[setIndex].title"
                                    style="width: 300px" maxlength="10">
                                </el-input>
                            </el-form-item>
                            <el-form-item label="必填">
                                <el-switch v-model="detailRequests[setIndex].required" active-text="开" inactive-text="关">
                                </el-switch>
                            </el-form-item>
                        </div>
                        <div class="ml10">
                            <el-form-item label="内容">
                                <div v-for="(children, chIndex) in detailRequests[setIndex].childrenDetails" :key="chIndex"
                                    class="mb10">
                                    <div class="flex-c-c">
                                        <el-input type="text" placeholder="请输入文本" v-model="children.title" maxlength="10"
                                            style="width: 250px" show-word-limit>
                                        </el-input>
                                        <i class="hover_pointer el-icon-remove-outline ml10"
                                            @click="deleteOption(detailRequests[setIndex].childrenDetails, chIndex)"></i>
                                    </div>
                                    <div>
                                        <el-rate v-model="formData.rate" :disabled="true" :colors="colors"> </el-rate>
                                    </div>
                                </div>
                            </el-form-item>
                        </div>

                        <div class="ml10">
                            <el-button type="primary" icon="el-icon-circle-plus-outline"
                                @click="addOption(detailRequests[setIndex].childrenDetails, detailRequests[setIndex].formType)">添加单个评分</el-button>
                        </div>
                    </el-form>

                    <el-form v-else-if="detailRequests[setIndex].formType == 'IMAGE'" class="commentForm pa10">
                        <p class="fs8 fwbold mb10 ml20">上传图片</p>
                        <el-alert title="上传的图片不能超过9张" type="warning">
                        </el-alert>
                        <div class="mt10">
                            <el-upload class="carouselUpload" :limit="9"
                                :class="{ 'disabled': detailRequests[setIndex].childrenDetails.length >= 9 }"
                                :action="$store.state.uploadingUrl" list-type="picture-card" :on-success="uploadImgSuccess"
                                multiple :on-remove="uploadImgRemove" :before-upload="uploadImgBeforeUpload"
                                :file-list="imgList">
                                <i class="el-icon-plus"></i>
                            </el-upload>
                        </div>
                    </el-form>

                    <el-form v-else-if="detailRequests[setIndex].formType == 'GPS'" class="commentForm pa10">
                        <p class="fs8 fwbold mb10 ml20">地址定位</p>
                        <div>
                            <el-form-item label="标题">
                                <el-input type="text" placeholder="地址定位" v-model="detailRequests[setIndex].title"
                                    style="width: 300px" maxlength="10">
                                </el-input>
                            </el-form-item>

                            <el-form-item label="必填">
                                <el-switch v-model="detailRequests[setIndex].required" active-text="开" inactive-text="关">
                                </el-switch>
                            </el-form-item>
                        </div>
                    </el-form>

                    <el-form v-else-if="detailRequests[setIndex].formType == 'USERINFO'" class="commentForm pa10">
                        <p class="fs8 fwbold mb10 ml20">个人信息</p>
                        <div>
                            <el-form-item label="标题">
                                <el-input type="text" placeholder="请输入标题" v-model="detailRequests[setIndex].title"
                                    style="width: 300px" maxlength="10">
                                </el-input>
                            </el-form-item>
                            <el-form-item label="必填">
                                <el-switch v-model="detailRequests[setIndex].required" active-text="开" inactive-text="关">
                                </el-switch>
                            </el-form-item>
                        </div>
                        <div class="ml10" v-for="(children, chIndex) in detailRequests[setIndex].childrenDetails"
                            :key="chIndex">
                            <el-form-item label="标题">
                                <div class="flex-c-c">
                                    <el-input type="text" style="width:200px" placeholder="请输入标题" v-model="children.title"
                                        maxlength="10" show-word-limit>
                                    </el-input>
                                    <i class="hover_pointer el-icon-remove-outline ml10"
                                        @click="deleteOption(detailRequests[setIndex].childrenDetails, chIndex)"></i>
                                </div>
                            </el-form-item>
                        </div>
                        <div class="ml10">
                            <el-button icon="el-icon-circle-plus-outline" type="primary"
                                @click="addOption(detailRequests[setIndex].childrenDetails, detailRequests[setIndex].formType)">添加单个标题内容</el-button>
                        </div>
                    </el-form>

                    <el-form v-else-if="detailRequests[setIndex].formType == 'TEXTAREAINPUT'" class="commentForm pa10">
                        <p class="fs8 fwbold mb10 ml20">输入框</p>
                        <div>
                            <el-form-item label="标题">
                                <el-input type="text" placeholder="请输入标题" v-model="detailRequests[setIndex].title"
                                    style="width: 300px" maxlength="10">
                                </el-input>
                            </el-form-item>
                            <el-form-item label="提示">
                                <el-input type="text" placeholder="请输入提示" v-model="detailRequests[setIndex].placeholder"
                                    style="width: 300px" maxlength="10">
                                </el-input>
                            </el-form-item>
                            <el-form-item label="必填">
                                <el-switch v-model="detailRequests[setIndex].required" active-text="开" inactive-text="关">
                                </el-switch>
                            </el-form-item>
                        </div>
                    </el-form>
                    <el-form v-else-if="detailRequests[setIndex].formType == 'DATEPICKER'" class="commentForm pa10">
                        <p class="fs8 fwbold mb10 ml20">日期选择</p>
                        <div>
                            <el-form-item label="标题">
                                <el-input type="text" placeholder="请输入标题" v-model="detailRequests[setIndex].title"
                                    style="width: 300px" maxlength="10">
                                </el-input>
                            </el-form-item>
                            <el-form-item label="必填">
                                <el-switch v-model="detailRequests[setIndex].required" active-text="开" inactive-text="关">
                                </el-switch>
                            </el-form-item>
                        </div>
                    </el-form>
                    <el-form v-else-if="detailRequests[setIndex].formType == 'TIMEPICKER'" class="commentForm pa10">
                        <p class="fs8 fwbold mb10 ml20">时间选择</p>
                        <div>
                            <el-form-item label="标题">
                                <el-input type="text" placeholder="请输入标题" v-model="detailRequests[setIndex].title"
                                    style="width: 300px" maxlength="10">
                                </el-input>
                            </el-form-item>
                            <el-form-item label="必填">
                                <el-switch v-model="detailRequests[setIndex].required" active-text="开" inactive-text="关">
                                </el-switch>
                            </el-form-item>
                        </div>
                    </el-form>
                    <el-form v-else-if="detailRequests[setIndex].formType == 'WEBSITE'" class="commentForm pa10">
                        <p class="fs8 fwbold mb10 ml20">网址</p>
                        <div>
                            <el-form-item label="标题">
                                <el-input type="text" placeholder="请输入标题" v-model="detailRequests[setIndex].title"
                                    style="width: 300px" maxlength="10">
                                </el-input>
                            </el-form-item>
                            <el-form-item label="必填">
                                <el-switch v-model="detailRequests[setIndex].required" active-text="开" inactive-text="关">
                                </el-switch>
                            </el-form-item>
                        </div>
                    </el-form>
                    <el-form v-else-if="detailRequests[setIndex].formType == 'ATTACHMENT'" class="commentForm pa10">
                        <p class="fs8 fwbold mb10 ml20">附件</p>
                        <div>
                            <el-form-item label="标题">
                                <el-input type="text" placeholder="请输入标题" v-model="detailRequests[setIndex].title"
                                    style="width: 300px" maxlength="10">
                                </el-input>
                            </el-form-item>
                            <el-form-item label="必填">
                                <el-switch v-model="detailRequests[setIndex].required" active-text="开" inactive-text="关">
                                </el-switch>
                            </el-form-item>
                        </div>
                    </el-form>
                </div>
                <el-form v-if="setIndex == 'btn'" class="commentForm pa10">
                    <p class="fs8 fwbold mb10">单选项</p>
                    <el-form-item label="按钮名称">
                        <el-input type="text" placeholder="请输入按钮名称" v-model="btnName" style="width: 300px"></el-input>
                    </el-form-item>
                </el-form>
            </div>
        </div>
    </div>
</template>

<script>
import vuedraggable from 'vuedraggable' //拖拽组件
export default {
    props: ['initFormData'],
    components: { vuedraggable },
    data() {
        return {
            colors: ["#99A9BF", "#F7BA2A", "#FF9900"],
            formData: {
                select: "",
                rate: 3,
                time: "",
            },
            btnName: "提 交",
            actionURL: this.$store.state.ossUrl,
            setIndex: 0,
            imgList: [],
            detailRequests: [],
            formTypeData: [
                { type: "SINGLE", title: "单选项", icon: "iconFormradio" },
                { type: "MULTIPLE", title: "多选项", icon: "iconFormcheckbox" },
                { type: "DROP_DOWN", title: "下拉选项", icon: "iconFormxialaxuanxiang" },
                { type: "TEXTAREA", title: "文本", icon: "iconFormwenbenkuang" },
                { type: "TEXTAREAINPUT", title: "输入框", icon: "iconFormbiaodanzujian-shurukuang" },
                // { type: "SCOURE", title: "评分", icon: "iconFormscore" },
                { type: "IMAGE", title: "图片", icon: "iconFormtupianshangchuan" },
                { type: "GPS", title: "地址定位", icon: "iconForma-dizhidingwei" },
                // { type: "USERINFO", title: "个人信息", icon: "iconFormgerenxinxi_o" },
                { type: "DATEPICKER", title: "日期选择", icon: "iconFormriqixuanze" },
                { type: "TIMEPICKER", title: "时间选择", icon: "iconFormriqixuanze" },
                { type: "WEBSITE", title: "网址", icon: "iconFormyuanshuju-wangzhi" },
                { type: "ATTACHMENT", title: "附件", icon: "iconFormattachment" },
            ],

        }
    },
    watch: {
        initFormData: {
            handler(obj) {
                this.detailRequests = obj.detailRequests ? obj.detailRequests : [];
                this.btnName = obj.buttonName ? obj.buttonName : '提 交';
                if (this.detailRequests && this.detailRequests.length > 0 && this.detailRequests[this.setIndex].formType === 'IMAGE') {
                    this.selectForm(this.setIndex);
                }
            },
            // deep: true,
            immediate: true
        }
    },
    methods: {
        uploadImgSuccess(res) {
            this.detailRequests[this.setIndex].childrenDetails.push({
                formType: "IMAGE",
                title: "",
                content: "",
                imgNum: this.$store.state.ossUrl + res.data
            });
        },
        uploadImgBeforeUpload(file) {
            if (file.type.indexOf("image/") == -1) {
                this.$message.info("格式错误，请上传图片文件")
                return false;
            }
        },
        uploadImgRemove(file, fileList) {
            let childrenDetails = [];
            for (let row of fileList) {
                let imgUrl = "";
                if ("response" in row) {
                    imgUrl = this.$store.state.ossUrl + response.data
                } else {
                    imgUrl = row.url
                }
                childrenDetails.push({
                    formType: "IMAGE",
                    title: "",
                    content: "",
                    imgNum: imgUrl
                });
            }
            this.detailRequests[this.setIndex].childrenDetails = childrenDetails;
        },
        /**@method 删除表单 */
        deleteForm(index) {
            if (this.setIndex == this.detailRequests.length - 1 && this.setIndex != 0) {
                this.setIndex = this.detailRequests.length - 2;
            }
            this.detailRequests.splice(index, 1);
        },
        /**@method 添加选项 */
        addOption(optionArray, type) {
            if (optionArray && optionArray.length < 6) {
                if (type == 'SCOURE' || type == 'USERINFO') {
                    optionArray.push({ formType: type, title: "标题" + (optionArray.length + 1), content: "" });
                } else {
                    optionArray.push({ formType: type, title: "选项" + (optionArray.length + 1), content: "", checked: false });
                }
            } else {
                //最多添加6个
                this.$message.info("选项最多只能添加6个")
            }
        },
        /**@method 删除选项 */
        deleteOption(optionArray, index) {
            optionArray.splice(index, 1);
        },
        selectForm(index) {
            this.setIndex = Number(index);
            if (this.detailRequests[index].formType == 'IMAGE') {
                this.imgList = [];
                for (let row of this.detailRequests[index].childrenDetails) {
                    this.imgList.push({
                        url: row.imgNum
                    })
                }
            }
        },
        /**@method 添加表单 */
        selectEnter(type, title) {
            if (type == 'DATEPICKER' || type == 'TIMEPICKER' || type == 'WEBSITE' || type == 'ATTACHMENT') {
                this.detailRequests.push({
                    formType: type,
                    title: title,
                    content: [],
                    require: false,
                });
            } else if (type == 'TEXTAREAINPUT') {
                this.detailRequests.push({
                    formType: type,
                    title: title,
                    content: "",
                    placeholder: "请填写内容",
                    require: false,
                });
            } else if (type === 'GPS') {
                this.detailRequests.push({
                    formType: type,
                    title: title,
                    content: "",
                    areaCode: "",
                    lat: "",
                    lng: "",
                    address:"",
                    require: false,
                });
            } else if (type === 'TEXTAREA') {
                this.detailRequests.push({
                    formType: type,
                    title: ""
                });
                // } else if (type === 'SCOURE' || type == 'USERINFO') {
                //     this.detailRequests.push({
                //         formType: type,
                //         title: title,
                //         content: "",
                //         require: false,
                //         childrenDetails: [
                //             { formType: type, title: "标题1", content: "" },
                //         ],
                //     });
            } else if (type === 'IMAGE') {
                this.imgList = [];
                this.detailRequests.push({
                    formType: type,
                    title: title,
                    content: "",
                    childrenDetails: [],
                });
            } else if (type === 'MULTIPLE') {
                this.detailRequests.push({
                    formType: type,
                    title: title,
                    content: "",
                    require: false,
                    select: [],
                    childrenDetails: [
                        { formType: type, title: "选项1", content: "", checked: false },
                        { formType: type, title: "选项2", content: "", checked: false },
                        { formType: type, title: "选项3", content: "", checked: false }
                    ],
                });
            } else {
                this.detailRequests.push({
                    formType: type,
                    title: title,
                    content: "",
                    require: false,
                    select: "0",
                    childrenDetails: [
                        { formType: type, title: "选项1", content: "", checked: false },
                        { formType: type, title: "选项2", content: "", checked: false },
                        { formType: type, title: "选项3", content: "", checked: false }
                    ],
                });
            }
            this.setIndex = Number(this.detailRequests.length - 1)
        },
    }
}
</script>
<style>
.carouselUpload .el-upload--picture-card,
.carouselUpload .el-upload-list--picture-card .el-upload-list__item {
    width: 100% !important;
}
</style>
<style lang="scss" scoped>
.formType {
    border-radius: 5px;
    padding: 0 20px;
    overflow: hidden;
    width: 282.5px;
    height: 600px;
    background: #fff;
    border: 1px solid #dcdfe6;

    .fwbold {
        padding: 20px 10px;
    }

    .formTypeList {
        display: flex;
        flex-wrap: wrap;
        align-content: flex-start;

        .formTypeItem {
            height: 80px;
            width: 80px;
            text-align: center;
            border: 1px solid #dcdfe6;
            cursor: pointer;

            .iconForm {
                font-size: 35px;
            }
        }
    }

    .formTypeItem:hover {
        background: #51CDCB;
        color: #fff;
    }
}

.yqInput {
    height: 32px;
    border: 1px solid #E4E7ED;
    border-radius: 4px;
    background-color: #F5F7FA;
    color: #C0C4CC;

    .iconForm {
        padding: 0 10px;
    }
}

.formContent {
    height: 600px;
    overflow-y: scroll;
    border-radius: 5px;
    width: 400px;
    background: #fff;
    border: 1px solid #dcdfe6;

    .formItem {
        cursor: move;
        border: 1px dashed #dcdfe6;
        padding: 10px;
        margin-top: 20px;

        .childrenDetails {
            margin-left: 10px;
        }

        p {
            font-size: 15px;

            .req {
                color: red;
                margin-right: 5px;
            }

        }

        .deleteForm {
            color: #F56C6C;
            z-index: 2;
            cursor: pointer;
            font-size: 18px;
        }

        .imageContent {
            position: relative;

            .imageDeleteForm {
                position: absolute;
                right: 10px;
                top: 10px;
                border-radius: 50%;
                z-index: 3;
            }
        }


        .commentForm {
            border: 1px solid #dcdfe6;
            padding-top: 10px;
            margin-top: 10px;
        }
    }

    .formItem.on {
        border: 1px solid #51CDCB;
    }

}

.formValue {
    border-radius: 5px;
    width: 350px;
    height: 600px;
    overflow-y: scroll;
    background: #fff;
    border: 1px solid #dcdfe6;
}

.nullImg {
    background: #eceef0;
    color: #dddfe1;
    font-size: 50px;
}
</style>